import { ModelAbstract } from '../../../services/model.abstract';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helpers } from '~/src/app/services/helpers';
import { Token } from '~/src/app/services/token';
import { MatDialog } from '@angular/material/dialog';
import obj2fd from 'obj2fd';
import { Data } from '@angular/router';
import { LoggedUser } from '~/src/app/services/logged-user';
@Injectable({ providedIn: 'root' })
export class OrganisationService extends ModelAbstract {
    public lastUsedFilters;
    public socialSites;
    constructor(private http: HttpClient, dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/organization';
    }

    getOrganization() {
        return this.http.get(
            this.apiLink + '/organization-tree',
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }


    getOrganizationGroupBy(filters: any = {}): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());

        this.lastUsedFilters = filters;

        const filtersdata = [];
        const Orgdata = [];
        if (filters) {
            if (filters.value !== null && filters.value !== undefined && filters.value !== '') {
                Orgdata.push(filters.value);
            }
        }
        if (Array.isArray(filters) && filters.length > 0) {
            filtersdata.push(filters);

        }
        if (Orgdata.length > 0) {
            options['params'] = { organization: Orgdata };
        }

        if (filtersdata.length > 0) {
            options['params'] = { socialTypes: filtersdata };
        }
        console.log('options', options);
        return this.http.get(this.apiLink + '/organizationGroupBy', options).toPromise().then(res => {
            // this.socialSites = res['socialSites'];

            return Promise.resolve(res);
        });
    }

    getGroupByOrganization(filters: any = {}): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());

        this.lastUsedFilters = filters;

        const filtersdata = [];
        const Orgdata = [];
        if (filters) {
            if (filters.value !== null && filters.value !== undefined && filters.value !== '') {
                Orgdata.push(filters.value);
            }
        }
        if (Array.isArray(filters) && filters.length > 0) {
            filtersdata.push(filters);

        }
        if (Orgdata.length > 0) {
            options['params'] = { organization: Orgdata };
        }

        if (filtersdata.length > 0) {
            options['params'] = { socialTypes: filtersdata };
        }
        console.log('options1', options);

        return this.http.get(this.apiLink + '/groupByorganization', options).toPromise().then(res => {

            if (res) {
                this.socialSites = res['Organization'];
            }
            return Promise.resolve(res);
        });
    }

    deleteOrganization(organizationID: number) {
        return this.http.delete(this.apiLink + `/${organizationID}`, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
