import { debounceTime } from "rxjs/operators";
import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { PostTemplateManagerDialogData } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.interfaces";
import { Subject } from "rxjs";
import { LanguageService } from "~/src/app/services/language.service";
import { Configs } from "~/src/app/configs/configs";
import { Helpers, MyErrorStateMatcher } from "~/src/app/services/helpers";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OrganizationController } from "~/src/app/components/organization-select/organization.service";
import { Category, Tag } from "~/src/app/modules/posts/template.interface";
import { TagsComponent } from "~/src/app/modules/posts/tags.component";
import { TagsService } from "~/src/app/modules/posts/tags.service";
import {
    MediaPostRequestKeys,
    MediaPostTemplateRequestKeys,
} from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.options";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";
import { PostTemplateController } from "~/src/app/modules/posts/post-template/post-template.controller";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { PostTemplateSystemTypesForCreateTemplate } from "~/src/app/modules/social-media-post/social-media-post.options";
import { PostTemplateSuccessCreateModalComponent } from "~/src/app/modules/social-media-post/post-template-success-create-modal/post-template-success-create-modal.component";
import { TemplateActionsService } from "~/src/app/modules/posts/template-actions.service";
import { PostSkeletonComponent } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.component";
import { forEach, has } from "lodash";
import { UserRolesService } from "~/src/app/services/user-roles.service";
import Utils from "~/src/app/core/utils";
import { NotifyService } from "~/src/app/services/notify.service";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { Partner } from "~/src/app/shared/types/partners.model";
import { PartnersService } from "~/src/app/modules/administration/partners/partners.service";
import { CoreConfig } from "~/src/app/core/core.config";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { OrganizationModel } from "~/src/app/modules/users/organizations/organization.model";
import { SocialMediaPlatformConfig } from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { LoggedUser } from "~/src/app/services/logged-user";
import { ConsoleLoggerService } from "~/src/app/shared/services/log/console-logger.service";

@Component({
    selector: "smd-post-template-manager",
    templateUrl: "./post-template-manager.component.html",
    styleUrls: ["./post-template-manager.component.scss"],
})
export class PostTemplateManagerComponent implements OnInit, AfterViewInit {
    @ViewChildren("postSkeleton")
    postSkeletons: QueryList<PostSkeletonComponent>;
    @ViewChild(PostSkeletonComponent) postSkeleton: PostSkeletonComponent;
    tabOptions = [
        {
            id: "customizePostTemplate",
            isEditor: true,
            tabNameKey: "schedule.customize.template",
            iconClass: "fa fa-edit",
        },
        {
            id: "preview",
            iconClass: "fa fa-eye",
            tabNameKey: "schedule.modal.post.preview",
        },
    ];
    platforms = Configs.socials;
    currentPlatform: { id: string; name: string };
    isPostTemplateCommentMode = false;
    getPostFormData = new Subject();
    isAdminMode = false;
    postTemplateControlNames = {
        SocialSite: PostFormControlNames.SocialSite,
        SocialType: PostFormControlNames.SocialType,
        Organization: PostFormControlNames.Organization,
        Categories: PostFormControlNames.Categories,
        Tags: PostFormControlNames.Tags,
        IsCopy: PostFormControlNames.IsCopy,
        SystemType: PostFormControlNames.SystemType,
        Partners: PostFormControlNames.Partners,
    };
    postTemplateFormGroup = new FormGroup({
        [this.postTemplateControlNames.SocialType]: new FormControl(
            { value: null, disabled: true },
            [Validators.required]
        ),
        [this.postTemplateControlNames.Organization]: new FormControl(null, [
            Validators.required,
        ]),
        [this.postTemplateControlNames.Categories]: new FormControl(null),
        [this.postTemplateControlNames.Tags]: new FormControl(null),
        [this.postTemplateControlNames.IsCopy]: new FormControl(null),
        [this.postTemplateControlNames.SocialSite]: new FormControl(null),
    });
    organizations = [];
    categories: Category[] = [];
    tags: Tag[] = [];
    socialSites = [];
    selectedSocialSites = [];
    postTemplateErrorMessages = {};
    errorMessages = null;
    errorTitle = null;
    postTemplateServerErrorMessages = {};
    matcher = new MyErrorStateMatcher();
    wasValidation = false;
    tagsComponent: TagsComponent;
    isPostTemplateEditMode = false;
    isPostNowFromTemplate = false;
    isDraftPostTemplateEditMode = false;
    postTemplate = {};
    postTemplateFormIsValid = true;
    currentTemplate = null;
    refreshTemplateList = false;
    draftID;
    createTemplatePending = false;
    showPreview = false;
    showCopyCheckbox = false;
    isAdmin = false;
    hasApprovementWorkflow = false;
    partners: Partner[] = [];
    private _currentTabIndex = 0;
    mainOrganization: Number = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PostTemplateManagerDialogData,
        public dialogRef: MatDialogRef<PostTemplateManagerComponent>,
        public language: LanguageService,
        public organizationController: OrganizationController,
        public socialSiteController: SocialSiteController,
        private tagsService: TagsService,
        private scrollTo: ScrollToService,
        private postTemplateController: PostTemplateController,
        private openModal: OpenModalService,
        private postService: SocialMediaPostService,
        private templateService: TemplateActionsService,
        private userService: UserRolesService,
        private partnerConfig: PartnerConfigService,
        private partnerService: PartnersService
    ) {
        this.hasApprovementWorkflow = this.partnerConfig.hasConfig(
            PartnerPermissions.ApprovementWorkflow
        );
        this.userService.currentUserCan("admin").then((res) => {
            this.isAdmin = res;
        });

        this.initialize();
    }

    ngOnInit() {
        this.initializeOnInit();
    }

    ngAfterViewInit(): void {
        setTimeout(() => { // runs after the change detection has run
            this.loadLazyElementsOfActiveTab();
        }, 0);
    }

    /**
     * Load lazy elements of active tab
     * Copied & modified from post-manager component
     */
    loadLazyElementsOfActiveTab() {
        // load media
        let loaded = false;
        this.postSkeletons.forEach((postSkeleton) => {
            if (this.tabOptions.findIndex((tab) => tab.id === postSkeleton.socialMediaType) === this.currentTabIndex) {
                postSkeleton.loadMedia();
                loaded = true;
                return;
            }
        });

        // fallback to first tab if no platform selected
        if (!loaded) {
            this.postSkeletons.first.loadMedia();
        }
    }

    /**
     * Create post template
     * @param {boolean} isApproved
     */
    createPostTemplate(isApproved: boolean = false) {

        this.getPostFormData.next();
        // this.postTemplateFormIsValid = true;
        this.validatePostTemplateForm();
        if (this.postTemplateFormIsValid && this.validatePostTemplateForm()) {
            this.createTemplatePending = true;
            const loader = this.openModal.loader(DialogLoaderComponent);
            const data = {
                ...this.getDataForRequest(),
                status: isApproved ? "active" : "waitingForApproval",
            };

            if (!!data[this.postTemplateControlNames.SocialSite]) {
                delete data[this.postTemplateControlNames.SocialSite];
            }

            loader.afterClosed().subscribe((result: any) => {
                if (!!result.isSuccess) {
                    this.openModal
                        .successModal(PostTemplateSuccessCreateModalComponent, {
                            message: this.language.getLine(
                                "post.template.success.templateCreated"
                            ),
                        })
                        .afterClosed()
                        .subscribe((createNew) => {
                            if (!!createNew) {
                                this.openModal.createTemplate(
                                    PostTemplateManagerComponent,
                                    {
                                        data: {
                                            isRefreshable: true,
                                            isAdminMode: this.isAdminMode,
                                            afterCreateCallback:
                                                !!this.data &&
                                                    !!this.data.afterCreateCallback
                                                    ? this.data
                                                        .afterCreateCallback
                                                    : () => { },
                                        },
                                    }
                                );
                            } else {
                                this.dialogRef.afterClosed().subscribe(() => {
                                    this.callAfterCreateCallback();
                                });
                            }

                            this.refreshTemplateList = !createNew;
                            this.closeDialog();
                        });
                } else {
                    this.createTemplatePending = false;
                    this.failedRequest(result.error);
                }
            });

            if (!!this.draftID) {
                this.postTemplateController.editItemWithPost(
                    this.draftID,
                    Utils.obj2fd(data),
                    (response) =>
                        loader.close({ isSuccess: true, response: response }),
                    (error) => loader.close({ isSuccess: false, error: error })
                );
            } else {
                this.postTemplateController.createItem(
                    Utils.obj2fd(data),
                    (response) =>
                        loader.close({ isSuccess: true, response: response }),
                    (error) => loader.close({ isSuccess: false, error: error })
                );
            }
        }
    }

    saveTemplate() {
        this.getPostFormData.next();

        if (this.postTemplateFormIsValid && this.validatePostTemplateForm()) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            loader.afterClosed().subscribe((result: any) => {
                if (!!result.isSuccess) {
                    this.openModal
                        .successModal(DialogSuccessComponent, {
                            message: this.language.getLine(
                                "schedule.edit.post.template.success"
                            ),
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.dialogRef.close(true);
                        });
                } else {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: this.readError(result.error),
                    });
                    this.failedRequest(result.error);
                }
            });

            this.postTemplateController.editItemWithPost(
                this.currentTemplate.templateID,
                Utils.obj2fd(this.getDataForRequest()),
                (response) =>
                    loader.close({ isSuccess: true, response: response }),
                (error) => loader.close({ isSuccess: false, error: error })
            );
        }
    }

    /**
     * Posting now from template
     */
    postNow() {
        this.isPostNowFromTemplate = true;
        this.postTemplateFormGroup
            .get(this.postTemplateControlNames.SocialSite)
            .setValidators(Validators.required);
        this.postTemplateFormGroup.updateValueAndValidity();
        this.getPostFormData.next();

        setTimeout(() => {
            if (
                this.postTemplateFormIsValid &&
                this.validatePostTemplateForm()
            ) {
                const loader = this.openModal.loader(DialogLoaderComponent);

                loader.afterClosed().subscribe((result: any) => {
                    if (!!result.isSuccess) {
                        this.openModal
                            .successModal(PostTemplateSuccessCreateModalComponent, {
                                message: this.language.getLine(
                                    "post.template.success.templateCreated"
                                ),
                            })
                            .afterClosed()
                            .subscribe((createNew) => {
                                if (!!createNew) {
                                    this.openModal.createTemplate(
                                        PostTemplateManagerComponent,
                                        {
                                            data: {
                                                isRefreshable: true,
                                                isAdminMode: this.isAdminMode,
                                                afterCreateCallback:
                                                    !!this.data &&
                                                        !!this.data.afterCreateCallback
                                                        ? this.data
                                                            .afterCreateCallback
                                                        : () => { },
                                            },
                                        }
                                    );
                                } else {
                                    this.dialogRef.afterClosed().subscribe(() => {
                                        this.callAfterCreateCallback();
                                    });
                                }
                                this.dialogRef.close();
                            });
                    } else {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: this.readError(result.error),
                        });
                        this.failedRequest(result.error);
                    }
                });

                this.postService
                    .instantPost([
                        { ...this.getDataForRequest(false), instantPost: 1 },
                    ])
                    .then((response) =>
                        loader.close({ isSuccess: true, response: response })
                    )
                    .catch((error) =>
                        loader.close({ isSuccess: false, error: error })
                    );
            }
        });
    }

    private readError(errorResponse) {
        let message = FormValidationService.readError(errorResponse).message;
        const error = errorResponse.error.error;

        if (error && error.info && error.info.messages) {
            forEach(error.info.messages, (messageObj) => {
                if (typeof messageObj === "string") {
                    message += " " + messageObj;
                } else if (typeof messageObj === "object") {
                    if (Object.keys(messageObj).length) {
                        forEach(Object.keys(messageObj), (key) => {
                            message += " " + messageObj[key];
                        });
                    }
                }
            });
        }

        return message;
    }

    setPost(post) {
        this.postTemplate = post;
    }

    /**
     * Get form control error by control name
     * @param {string} controlName
     * @return {string}
     */
    getControlError(controlName: string) {
        const error = this.postTemplateErrorMessages[controlName];
        return error ? error : "";
    }

    /**
     * Change active tab event
     * @param {MatTabChangeEvent} event
     */
    onTabChange(event: MatTabChangeEvent) {
        const activeTabIndex = event.index;

        this.currentTabIndex = activeTabIndex;

        if (this.currentTabIndex === 1) {
            this.showPreview = true;
        } else {
            this.showPreview = false;
        }

        this.changeModalWidth();
    }

    /**
     * @description for matSelects
     * @param select
     * @param dataArray
     */
    selectAll(select: FormControl, dataArray, modelValueProperty) {
        select.setValue(dataArray.map((data) => data[modelValueProperty]));
    }

    /**
     * Select all social site using validators
     */
    selectAllSocialSite(): void {
        if (
            this.postTemplateFormGroup.contains(
                this.postTemplateControlNames.SocialSite
            )
        ) {
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.SocialSite)
                .setValue(
                    CommonPostHelpers.selectAllSocialSite(
                        this.socialSites,
                        this.selectedSocialSites
                    )
                );
        }
    }

    /**
     * Get data for post template request
     * @return {{[p: string]: string}}
     */
    getDataForRequest(isStringify = true) {
        const postTemplateFormData = this.postTemplateFormGroup.getRawValue();
        const tags = this.postTemplateFormGroup.get(
            this.postTemplateControlNames.Tags
        ).value;
        const organizationIDs = !this.isAdminMode
            ? this.postTemplateFormGroup.get(
                this.postTemplateControlNames.Organization
            ).value
            : [];
        const category = this.postTemplateFormGroup.get(
            this.postTemplateControlNames.Categories
        ).value;

        const result = isStringify
            ? {
                ...postTemplateFormData,
                ...this.postTemplate,
                [this.postTemplateControlNames.IsCopy]: !postTemplateFormData[
                    this.postTemplateControlNames.IsCopy
                ]
                    ? null
                    : true,
                [this.postTemplateControlNames.Tags]:
                    this.arrayToString(tags),
                [this.postTemplateControlNames.Organization]:
                    this.arrayToString(organizationIDs),
                [MediaPostTemplateRequestKeys.fileIDs]: this.arrayToString(
                    this.postTemplate[MediaPostTemplateRequestKeys.fileIDs]
                ),
                [this.postTemplateControlNames.Categories]: !!category
                    ? this.arrayToString([category])
                    : null,
            }
            : {
                ...postTemplateFormData,
                ...this.postTemplate,
                [this.postTemplateControlNames.IsCopy]: !postTemplateFormData[
                    this.postTemplateControlNames.IsCopy
                ]
                    ? null
                    : true,
                [this.postTemplateControlNames.Categories]: !!category
                    ? [category]
                    : null,
            };

        if (
            this.isPostNowFromTemplate &&
            !!result[this.postTemplateControlNames.IsCopy]
        ) {
            delete result[this.postTemplateControlNames.IsCopy];
        }

        //  If this is instant post from template
        if (this.isPostNowFromTemplate) {
            if (has(result, MediaPostTemplateRequestKeys.fileIDs)) {
                delete result[MediaPostTemplateRequestKeys.fileIDs];
            }

            if (has(this.postTemplate, MediaPostTemplateRequestKeys.fileIDs)) {
                result[MediaPostRequestKeys.fileIDs] =
                    this.postTemplate[MediaPostTemplateRequestKeys.fileIDs];
            }

            if (has(result, MediaPostTemplateRequestKeys.carouselIDs)) {
                delete result[MediaPostTemplateRequestKeys.carouselIDs];
            }

            if (
                has(this.postTemplate, MediaPostTemplateRequestKeys.carouselIDs)
            ) {
                result[MediaPostRequestKeys.carouselIDs] =
                    this.postTemplate[MediaPostTemplateRequestKeys.carouselIDs];
            }
        }

        forEach(result, (value, controlName) => {
            if (value === "") {
                result[controlName] = null;
            }
        });

        return result;
    }

    getDataForDraft(isStringify = true, isDraft = true) {
        if (!this.postSkeletons || !this.postSkeletons.first) {
            return {};
        }

        const postData = this.postSkeletons.first.getDataForRequest({
            removeMedias: isDraft,
            replaceLineBreakSign: isDraft,
        });

        if (isStringify) {
            forEach(postData, (value, name) => {
                if (value instanceof Array) {
                    postData[name] = JSON.stringify(value);
                }
            });
        }

        return {
            ...this.getDataForRequest(),
            ...postData,
        };
    }

    /**
     * Success draft save
     * @param event
     */
    successDraftSave(event) {
        this.draftID = event.draftID;
        this.refreshTemplateList = true;
    }

    /**
     * Close modal
     */
    closeDialog() {
        if (this.refreshTemplateList) {
            this.dialogRef.afterClosed().subscribe(() => {
                this.callAfterCreateCallback();
            });
        }

        this.dialogRef.close(this.refreshTemplateList);
    }

    /**
     * Get selected categories
     * @return {any}
     */
    get categoryChipsInput() {
        let value = this.postTemplateFormGroup.get(
            this.postTemplateControlNames.Categories
        ).value;

        if (!!value) {
            value = [value];
        } else {
            value = null;
        }

        return value;
    }

    /**
     * Get selected tags
     * @return {any}
     */
    get tagsChipsInput() {
        return this.postTemplateFormGroup.get(
            this.postTemplateControlNames.Tags
        ).value;
    }

    /**
     * Get active tab index
     * @return {number}
     */
    get currentTabIndex(): number {
        return this._currentTabIndex;
    }

    /**
     * Set active tab index
     * @param {number} value
     */
    set currentTabIndex(value: number) {
        this._currentTabIndex = value;
    }

    get systemTypes() {
        return PostTemplateSystemTypesForCreateTemplate;
    }

    private initialize() {
        this.refreshTemplateList =
            !!this.data && has(this.data, "isRefreshable")
                ? this.data.isRefreshable
                : false;
        this.currentPlatform =
            this.data && this.data.initialPlatform
                ? this.data.initialPlatform
                : null;
        this.currentPlatform =
            this.data && this.data.template && !this.data.isCopy
                ? Configs.socials.find(
                    (platform) =>
                        platform.id === this.data.template.socialType
                )
                : this.currentPlatform;
        this.currentTemplate =
            !!this.data && !!this.data.template ? this.data.template : null;
        this.isDraftPostTemplateEditMode =
            !!this.currentTemplate &&
            this.currentTemplate.status === "draft" &&
            !this.data?.isCopy;
        this.isPostTemplateEditMode =
            this.data &&
            !!this.data.template &&
            !this.data.isCopy &&
            !this.isDraftPostTemplateEditMode;
        if (this.data?.isTemplateFromPost) {
            this.isPostTemplateEditMode =
                this.isPostTemplateEditMode &&
                !this.data.isTemplateFromPost;
        }
        this.isPostTemplateCommentMode =
            !!this.data && !!this.data.isCommentMode;
        this.isAdminMode = Utils.lodash.get(this.data, "isAdminMode", false);

        if (this.isDraftPostTemplateEditMode) {
            this.draftID = this.currentTemplate.templateID;
        }

        if (!!this.currentPlatform) {
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.SocialType)
                .setValue(this.currentPlatform.id);
        }

        if (!!this.currentTemplate) {
            const organizationIDs = !!this.currentTemplate["organizationIDs"]
                ? this.currentTemplate["organizationIDs"]
                : !!this.currentTemplate["organizationID"]
                    ? [this.currentTemplate["organizationID"]]
                    : [];
            const category =
                !!this.currentTemplate["categories"] &&
                    !!this.currentTemplate["categories"].length
                    ? this.currentTemplate["categories"].map(
                        (cat) => cat.name
                    )[0]
                    : null;
            const tags = !!this.currentTemplate["tags"]
                ? this.currentTemplate["tags"]
                : [];

            if (!this.isAdminMode) {
                this.postTemplateFormGroup
                    .get(this.postTemplateControlNames.Organization)
                    .setValue(organizationIDs.map((org) => Number(org)));
            }

            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.Categories)
                .setValue(category);
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.Tags)
                .setValue(tags);

            if (organizationIDs.length) {
                this.initSocialSites(organizationIDs);
            }
        }

        this.formInit();

        if (this.isAdminMode) {
            this.partnerService.getPartners().then((response: any) => {
                this.partners = Helpers.orderBy(response.partners, "name");

                this.postTemplateFormGroup
                    .get(this.postTemplateControlNames.Partners)
                    .setValue(
                        Utils.lodash.get(this.currentTemplate, "partnerIDs", [])
                    );
            });
        } else {
            this.initOrganizations();
        }

        // this.initTags();

        if (this.isPostTemplateCommentMode) {
            forEach(
                this.postTemplateFormGroup.controls,
                (control, controlName) => {
                    this.postTemplateFormGroup.get(controlName).disable();
                }
            );
        }
    }

    private initializeOnInit() { }

    private formInit() {
        /**
         * This modal opened in administration page, then I add this controls to form group
         */
        if (this.isAdminMode) {
            // Add system type control
            this.postTemplateFormGroup.addControl(
                this.postTemplateControlNames.SystemType,
                new FormControl(
                    Utils.lodash.get(this.currentTemplate, "systemType", null),
                    [Validators.required]
                )
            );

            // Add partner select control
            this.postTemplateFormGroup.addControl(
                this.postTemplateControlNames.Partners,
                new FormControl(
                    {
                        value: [],
                        disabled:
                            Utils.lodash.get(
                                this.currentTemplate,
                                "systemType",
                                null
                            ) !== CoreConfig.getSystemTypes().Branded,
                    },
                    [Validators.required]
                )
            );

            this.postTemplateFormGroup.removeControl(
                this.postTemplateControlNames.Organization
            );
        } else {
            /**
             * If change the selected organizations, get social sites by organizationIDs
             */
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.Organization)
                .valueChanges.pipe(debounceTime(350))
                .subscribe((organizationIDs) => {
                    this.showCopyCheckbox = organizationIDs.length > 1;
                    this.initSocialSites(organizationIDs);
                });
        }

        /**
         * If the selected system type is branded, activate partner select
         */
        if (
            Utils.lodash.has(
                this.postTemplateFormGroup.controls,
                this.postTemplateControlNames.SystemType
            )
        ) {
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.SystemType)
                .valueChanges.subscribe((systemType) => {
                    if (systemType === CoreConfig.getSystemTypes().Branded) {
                        this.postTemplateFormGroup
                            .get(this.postTemplateControlNames.Partners)
                            .enable();
                    } else {
                        this.postTemplateFormGroup
                            .get(this.postTemplateControlNames.Partners)
                            .disable();
                    }
                });
        }

        if (
            this.postTemplateFormGroup.contains(
                this.postTemplateControlNames.SocialSite
            )
        ) {
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.SocialSite)
                .valueChanges.subscribe((value) => {
                    this.selectedSocialSites = this.socialSites.filter((site) =>
                        (value || []).includes(site.siteID)
                    );
                    this.socialSites = CommonPostHelpers.filterSocialSiteList(
                        this.socialSites,
                        this.selectedSocialSites
                    );
                });
        }
    }

    private failedRequest(error) {
        this.postTemplateServerErrorMessages = {};
        this.postTemplateServerErrorMessages =
            FormValidationService.setFormControlsIncorrect(
                error,
                this.postTemplateFormGroup,
                this.postTemplateErrorMessages
            );
        this.postTemplateErrorMessages = {
            ...this.postTemplateErrorMessages,
            ...this.postTemplateServerErrorMessages,
        };
    }

    private validatePostTemplateForm(): boolean {
        this.postTemplateErrorMessages = {};
        if (this.postTemplateFormGroup.invalid) {
            this.setControlErrors();
            for (const controlName in this.postTemplateErrorMessages) {
                this.postTemplateFormGroup.get(controlName).markAsTouched();
            }

            this.scrollTo.scrollTo({
                target: "post-template-extension-form",
            });

            return false;
        }
        return true;
    }

    /**
     * Call after create callback
     */
    private callAfterCreateCallback() {
        if (!!this.data && !!this.data.afterCreateCallback) {
            this.data.afterCreateCallback();
        }
    }

    /**
     * Get common form control errors
     */
    setControlErrors() {
        this.wasValidation = true;
        this.postTemplateErrorMessages = FormValidationService.getMessages(
            this.postTemplateFormGroup.controls
        );
    }

    /**
     * Array to string
     * @param {any[]} array
     * @return {string}
     */
    private arrayToString(array: any[]) {
        return !!array && array.length ? JSON.stringify(array) : null;
    }

    /**
     * Get organizations
     */
    private initOrganizations() {
        this.organizationController.getItems((response) => {
            this.organizations = response.organizations;

            if (this.currentTemplate) {
                const organizationIDs: number[] =
                    this.postTemplateFormGroup.get(
                        this.postTemplateControlNames.Organization
                    ).value;
                const allOrganizationIDs = (
                    this.organizations as OrganizationModel[]
                ).map((org) => org.organizationID);
                this.postTemplateFormGroup
                    .get(this.postTemplateControlNames.Organization)
                    .setValue(
                        organizationIDs.filter((id) =>
                            allOrganizationIDs.includes(id)
                        )
                    );
            }

            if (!this.currentTemplate || this.data.isCopy) {
                this.mainOrganization = LoggedUser.getMainOrganization().organizationID;
            } else {
                if (this.currentTemplate.organizationID) {
                    if (this.organizations.find((org) => org.organizationID == this.currentTemplate.organizationID)) {
                        this.mainOrganization = this.currentTemplate.organizationID;
                    } else {
                        this.mainOrganization = null;
                    }
                } else {
                    this.mainOrganization = LoggedUser.getMainOrganization().organizationID;
                }
            }

            // if main organization is not in organizations list, then set it to null since we don't have access to it
            // or if we are in copy mode
            if (this.mainOrganization && !this.organizations.find((org) => org.organizationID == this.mainOrganization)) {
                this.mainOrganization = null;
            }

        });
    }

    /**
     * Init tags component and get all tag
     */
    private initTags() {
        if (!this.tagsComponent) {
            this.tagsComponent = new TagsComponent(this.tagsService);
        }

        this.tagsComponent.getItems(({ tags }: { tags: Tag[] }) => {
            this.tags = tags;
        });
    }

    /**
     * Get social sites
     */
    private initSocialSites(organizationIDs: number[]) {
        if (organizationIDs.length) {
            const filters = {
                socialType: this.currentPlatform.id,
            };

            this.socialSiteController
                .getItemsByIDs("organizationID", organizationIDs, filters)
                .then((response: any) => {
                    this.socialSites = Helpers.orderBy(
                        response.socialSites,
                        "name"
                    );
                });
        } else {
            this.socialSites = [];
            this.postTemplateFormGroup
                .get(this.postTemplateControlNames.SocialSite)
                .setValue([]);
        }
    }

    /**
     * Set modal width
     */
    private changeModalWidth() {
        const width =
            this.currentTabIndex ===
                this.tabOptions.findIndex((platform) => platform.id === "preview")
                ? "675px"
                : "80vw";
        this.dialogRef.updateSize(width);
    }

    /**
     * Check is refreshable
     * @return {boolean}
     */
    private isRefreshable() {
        return !!this.data && !!this.data.isRefreshable;
    }

    private setErrorMessages(
        errors: { [key: string]: { [key: string]: string } } | string
    ) {
        if (errors instanceof Object) {
            this.errorMessages = Object.keys(errors)
                .map((platform) => {
                    const config =
                        SocialMediaPlatformConfig.getConfig(platform);
                    const messages = errors[platform];
                    return (
                        `<strong class="font-bold"><i class="${config.iconClass
                        }"></i> ${Utils.lodash.capitalize(
                            platform
                        )}</strong><br>` +
                        Object.keys(messages)
                            .map((fieldName) => {
                                return `<strong class="font-weight-bold">${LanguageService.getLine(
                                    `post.fieldName.${fieldName}`
                                )}:</strong> ${messages[fieldName]}`;
                            })
                            .join("<br>")
                    );
                })
                .join("<br><br>");
        } else {
            this.errorMessages = errors;
        }
    }

    private setErrorTitle(title: string | null) {
        this.errorTitle = title;
    }
}
