import { KeyValue } from '@angular/common';
import {
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
    Debounce,
    EditOrgErrorStateMatcher,
    FacebookUser,
    Helpers,
} from "~/src/app/services/helpers";
import { Token } from "~/src/app/services/token";
import { Facebook } from "~/src/app/modules/users/Facebook.class";
import { FacebookService } from "ngx-facebook";
import { FacebookDialogComponent } from "~/src/app/modules/users/Facebook-dialog.component";
import { SocialSiteComponent } from "~/src/app/components/social-site-select/social-site.component";
import { SocialSiteService } from "~/src/app/components/social-site-select/social-site.service";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { SocialManagerComponent } from "~/src/app/modules/users/social-manager/social-manager.component";
import { SocialManagerService } from "~/src/app/modules/users/social-manager/social-manager.service";
import { CreateOrgModalComponent } from "~/src/app/modules/users/organizations/create-org-modal/create-org-modal.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { SocialSiteEditDialogComponent } from "~/src/app/modules/users/social-site-edit-dialog/social-site-edit-dialog.component";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { UsersComponent } from "~/src/app/modules/users/users/users.component";
import { UsersService } from "~/src/app/modules/users/users/users.service";
import { OrganisationService } from "~/src/app/modules/users/organizations/organisation.service";
import { OrganizationController } from "~/src/app/components/organization-select/organization.service";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { LanguageService } from "~/src/app/services/language.service";
import { ValidatorsService } from "~/src/app/services/validators.service";
import { NotifyService } from "~/src/app/services/notify.service";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { environment } from "~/src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { LinkedInService } from "~/src/app/services/linked-in.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { SocialChannels } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import Utils from "~/src/app/core/utils";
import { PartnersService } from "~/src/app/modules/administration/partners/partners.service";
import { TwitterAPI } from "~/src/app/modules/users/twitter.service";
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import { SocialSiteUtils } from "~/src/app/modules/users/social-site.utils";
import { BackendService } from "~/src/app/core/backend.service";
import { GmbLocationsDialogComponent } from "~/src/app/modules/users/gmb/gmb-locations-dialog.component";
import { GmbApiLocationService } from "~/src/app/modules/social-media-post/gmb/gmb-api-location.service";
import { IOrganizationTreeItem } from '~/src/app/modules/users/organizations/organization.interface'
import { socialSiteAppearance } from "../../social-media-post/social-media-platforms-config";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { AbstractControl } from "@angular/forms";
import { OrganizationGroupbyService } from '~/src/app/services/organization-groupby.service';

declare const $;

const SITE_TYPE = {
    Profile: "profile",
    Page: "page",
    Group: "group",
};
const SOCIAL_PLATFORMS = {
    Facebook: SOCIAL_MEDIA_TYPE_FACEBOOK,
    LinkedIn: SOCIAL_MEDIA_TYPE_LINKEDIN,
    Twitter: SOCIAL_MEDIA_TYPE_TWITTER,
    Instagram: SOCIAL_MEDIA_TYPE_INSTAGRAM,
    Gmb: SOCIAL_MEDIA_TYPE_GMB,
};

type TYPE_PROFILE = "profile";
type TYPE_PAGE = "page";
type TYPE_GROUP = "group";
type SOCIAL_PLATFORMS =
    | "facebook"
    | "linkedIn"
    | "twitter"
    | "instagram"
    | "gmb";

@Component({
    selector: "smd-organizations",
    templateUrl: "./organizations.component.html",
    styleUrls: ["./organizations.component.scss"],
    providers: [ComponentHelpers, TwitterAPI],
})
export class OrganizationsComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild("newFeedURLInput", { static: true }) newFeedURLInput: ElementRef;
    @ViewChild("userIdFilterInput", { static: true })
    userIdFilterInput: ElementRef;
    @ViewChild("userNameFilterInput", { static: true })
    userNameFilterInput: ElementRef;

    /**
     * mat-table declrations for user's table
     * */
    userTableColumns: string[] = [
        "uid",
        "name",
        "email",
        "regdate",
        "roleName",
        "status",
    ];
    orgUserSource = new MatTableDataSource();
    // for panel
    isLeftVisible = true;
    activeDialogLoader: MatDialog;
    fb: Facebook;
    fbDialog: FacebookDialogComponent;
    gmbLocationsDialog: GmbLocationsDialogComponent;
    socialSitesComponent: SocialSiteComponent;
    socialManagerComponent: SocialManagerComponent;
    socialSites: SocialSiteInterface[] = [];
    socialSitesUser: SocialSiteInterface[] = [];
    socialSitesPage: SocialSiteInterface[] = []; // for Facebook
    linkedInPages: SocialSiteInterface[] = [];
    linkedInProfiles: SocialSiteInterface[] = [];
    twitterProfiles: SocialSiteInterface[] = [];
    instagramPages: SocialSiteInterface[] = [];
    gmbProfiles: SocialSiteInterface[] = [];
    gmbPages: SocialSiteInterface[] = [];
    socialSitesGroup: SocialSiteInterface[] = [];
    organizationTree = []; // organizations objects
    organizations = [];
    title = "Connected Social Sites - Grouped by Platforms";
    commonControlNames = {
        Organization: PostFormControlNames.Organization
    };
    commonFormGroup = new FormGroup({

        [this.commonControlNames.Organization]: new FormControl({
            value: null,
            disabled: this.socialSiteController.getSocialSitesInProgress,
        }),

    });
    organizationControl: AbstractControl;
    /**
     * Current organization's object
     */
    organization = {
        organizationID: 0,
        name: "",
        address: "",
        desc: "",
        createdDate: "",
        childNumber: 0,
        childs: [],
        rssArr: [],
    };
    organizationCounterAll = 0; // organizations number (all)
    isActive = true; // organization's activity status
    activeSocialNetwork = SOCIAL_MEDIA_TYPE_FACEBOOK;
    isEditSuccess = false;
    feeds = [];
    users = [];
    usersComponent: UsersComponent;
    orgUsers = [];
    /**
     * Forms group and validators
     */
    editOrganizationFormErrors: object = {};
    matcher = new EditOrgErrorStateMatcher();
    searchSocialNetworkForm = new FormGroup({});
    editOrganizationForm = new FormGroup({
        name: new FormControl("", [
            Validators.required,
            Validators.maxLength(55),
        ]),
        address: new FormControl("", [
            Validators.maxLength(55),
        ]),
        desc: new FormControl("", [
            Validators.maxLength(255),
        ]),
    });
    preLoader;
    paginationController: PaginationController = new PaginationController();
    userTableFilter = [];
    socialSiteService;
    isLoggedInWithLinkedIn: boolean;
    public isActiveSocialChannelId: SocialChannels = SOCIAL_MEDIA_TYPE_FACEBOOK;
    showedOrganizations = [];
    linkedInLoginWindow;
    initialOrganizationID: string = null;
    initialTabName: string = null;
    initialSocialType: string = null;
    initialSocialSiteID: string = null;
    showComponentA = true;

    initializedOrganizations: boolean = false;

    selectedOrganizationData = null;

    private lastViewedOrganizationLocalStorageKey = "lastViewedOrganization";

    constructor(
        public dialog: MatDialog,
        public fbService: FacebookService,
        public gmbApiService: GmbApiLocationService,
        public socialSitesService: SocialSiteService,
        public socialManagerService: SocialManagerService,
        public organizationController: OrganizationController,
        public userService: UsersService,
        public service: OrganisationService,
        private socialSiteController: SocialSiteController,
        public twitterAPI: TwitterAPI,
        public el: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private partnerService: PartnersService,
        private http: HttpClient,
        private _validator: ValidatorsService,
        private linkedinService: LinkedInService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private openModal: OpenModalService,
        private componentHelpers: ComponentHelpers,
        private backend: BackendService,
        private OrganizationGroupbyService: OrganizationGroupbyService
    ) {
        this.fb = new Facebook(fbService, dialog);
        this.isLoggedInWithLinkedIn = this.linkedinService.isLoggedIn();
        this.socialSitesComponent = new SocialSiteComponent(socialSitesService);
        this.socialManagerComponent = new SocialManagerComponent(
            socialManagerService
        );
        this.socialSiteService = socialSitesService;
        this.usersComponent = new UsersComponent(userService);

        this.getInitialRouteData();

        this.initSocialSites();
        this.orgUserSource.filterPredicate = (
            data: any,
            filtersJson: string
        ) => {
            const matchFilter = [];
            let filters;
            try {
                filters = JSON.parse(filtersJson);
            } catch (e) {
                return data;
            }

            filters.forEach((filter) => {
                let val = data[filter.id] === null ? "" : data[filter.id];
                if (filter.type === "number") {
                    filter.value = parseInt(filter.value, 10);
                    val = parseInt(val, 10);
                    matchFilter.push(val === filter.value);
                }

                if (filter.type === "string") {
                    filter.value = filter.value.toLowerCase();
                    val = val.toLowerCase();
                    matchFilter.push(val.includes(filter.value));
                }
            });

            return matchFilter.every(Boolean);
        };
    }

    ngOnInit() {
        this.orgUserSource.paginator = this.paginator;
        this.preLoader = this.openModal.loader(DialogLoaderComponent);
        this.organizationControl = this.commonFormGroup.get(this.commonControlNames.Organization);
        this.getOrganizations();
        this.activatedRoute.params.subscribe(() => {
            if (this.initializedOrganizations) {
                this.getInitialRouteData();
                this.loadUIByRouteParams();
            }
        });
    }
    initSocialSites() {
        this.socialSiteController.getItems((response) => {
            this.socialSites = response.socialSites;
            this.socialSites = this.socialSites.filter(
                (item) =>
                    socialSiteAppearance?.[item.socialType]?.schedule !== false
            );
        });
    }
    getInitialRouteData() {
        this.initialOrganizationID = Utils.get<ActivatedRoute>(
            this.activatedRoute,
            ["snapshot", "params", "organizationID"],
            null
        );
        this.initialTabName = Utils.get<ActivatedRoute>(
            this.activatedRoute,
            ["snapshot", "params", "tabName"],
            null
        );
        this.initialSocialType = Utils.get<ActivatedRoute>(
            this.activatedRoute,
            ["snapshot", "params", "socialType"],
            null
        );
        this.initialSocialSiteID = Utils.get<ActivatedRoute>(
            this.activatedRoute,
            ["snapshot", "params", "socialSiteID"],
            null
        );
    }

    addShowedOrganization(level: number, organization) {
        const indexs = [];

        if (!!organization.children) {
            level += 1;
            this.showedOrganizations[level] = organization;
        }

        Utils.lodash.forEach(this.showedOrganizations, (value, index) => {
            if (index > level) {
                indexs.push(index);
            }
        });

        if (!!indexs.length) {
            this.showedOrganizations.splice(indexs[0], indexs.length);
        }
    }

    updateShowedOrganization() {
        const newShowedOrganizations = [
            {
                children: {
                    ...this.organizationTree,
                },
            },
        ];
        let keys = `[${Object.keys(this.organizationTree)[0]}]`;

        this.showedOrganizations.forEach((organization, index) => {
            let value;

            if (index > 0) {
                value = this.organizationTree[organization.organizationID];
            }

            if (index > 1) {
                keys += `.children[${organization.organizationID}]`;
                value = Utils.lodash.get(this.organizationTree, keys, null);
            }

            if (!!value) {
                newShowedOrganizations[index] = value;
            }
        });

        this.showedOrganizations = newShowedOrganizations;
    }

    /**
     * Get all organization from API
     *
     * API URL: '/api/organization/organization-tree'
     */
    getOrganizations() {
        return this.service
            .getOrganization()
            .then((data) => {
                this.organizationTree = data["organizationTree"];
                //this.sortOrganizationTree();
                this.organizationCounterAll = data["organizationCount"] || 0;

                if (!this.showedOrganizations.length) {
                    this.showedOrganizations.push({
                        children: {
                            ...this.organizationTree,
                        },
                    });
                }

                this.loadUIByRouteParams();

                this.initializedOrganizations = true;

                this.preLoader.close();

                return data;
            })
            .catch((error) => {
                NotifyService.warning(
                    FormValidationService.readError(error).message,
                    ""
                );
                this.preLoader.close();

                return error;
            });
    }

    /* sortOrganizationTree() {
        if (this.organizationTree) {
            for (const rootID in this.organizationTree) {
                this._sortTree(this.organizationTree[rootID]);
            }
        }
    }

    private _sortTree(node: IOrganizationTreeItem) {
        if (node.childNo > 0) {
            const childrenArray = Object.values(node.children);
            childrenArray.sort((a, b) => a.name.localeCompare(b.name));
            node.children = {};
            childrenArray.forEach((child, index) => {
                node.children[index] = child;
                this._sortTree(child);
            });
        }   
    } */

    /**
     * Find organization by organizationID in tree
     * @param {string} organizationID
     * @param {{[p: string]: any}} organizations
     * @return {any}
     */
    findOrganizationByID(
        organizationID: string,
        organizations: { [key: string]: any }
    ): any {
        let _organization = null;

        if (organizations && organizations instanceof Object) {
            if (organizations[organizationID]) {
                _organization = organizations[organizationID];
            } else {
                for (const _organizationID in organizations) {
                    const organization = organizations[_organizationID];

                    if (
                        organization &&
                        organization.children &&
                        !_organization
                    ) {
                        _organization = this.findOrganizationByID(
                            organizationID,
                            organization.children
                        );
                    }
                }
            }
        }

        return _organization;
    }

    /**
     * Load organization edit panel UI by route params
     */
    loadUIByRouteParams(): void {
        const ORGANIZATION_MODIFY_TAB_EDIT = "edit";
        const ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS = "social-networks";
        const ORGANIZATION_MODIFY_TAB_USERS = "users";

        if (this.initialOrganizationID) {
            const initialOrganization = this.findOrganizationByID(
                this.initialOrganizationID,
                this.organizationTree
            );
            const initialTabName = this.initialTabName
                ? this.initialTabName
                : ORGANIZATION_MODIFY_TAB_EDIT;
            const initialSocialType = this.initialSocialType
                ? this.initialSocialType
                : SOCIAL_MEDIA_TYPE_FACEBOOK;
            const organizationTabsMap = {
                [ORGANIZATION_MODIFY_TAB_EDIT]: 0,
                [ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS]: 1,
                [ORGANIZATION_MODIFY_TAB_USERS]: 2,
            };
            const socialTypesMap = {
                [SOCIAL_MEDIA_TYPE_FACEBOOK]: 0,
                [SOCIAL_MEDIA_TYPE_LINKEDIN]: 1,
                [SOCIAL_MEDIA_TYPE_TWITTER]: 2,
                [SOCIAL_MEDIA_TYPE_INSTAGRAM]: 3,
                [SOCIAL_MEDIA_TYPE_GMB]: 4,
            };
            const initialTabIndex = organizationTabsMap[initialTabName];
            const initialSocialTypeIndex = socialTypesMap[initialSocialType];

            if (initialOrganization) {
                // go to organization edit panel
                this.editOrg(initialOrganization, this.initialOrganizationID);

                if (initialTabIndex >= 0) {
                    setTimeout(() => {
                        // go to specify tab
                        this.el.nativeElement
                            .querySelectorAll(".organization-tab-nav-item")
                        [initialTabIndex].querySelector("a")
                            .click();

                        // go to specify social network tab
                        if (
                            initialSocialTypeIndex >= 0 &&
                            initialTabName ===
                            ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS
                        ) {
                            setTimeout(() => {
                                this.el.nativeElement
                                    .querySelectorAll(".nav-item--social-type")
                                [initialSocialTypeIndex].querySelector("a")
                                    .click();
                            }, 0);
                        }
                    }, 0);
                }
            }

            this.initialOrganizationID = null;
            this.initialTabName = null;
            this.initialSocialType = null;
        }
    }

    /**
     * Refresh DOM when org create/edit/deleted
     */
    @Debounce()
    updateOrganization() {
        this.preLoader = this.openModal.loader(DialogLoaderComponent);
        return this.getOrganizations().then((response) => {
            this.updateShowedOrganization();
            this.preLoader.close();
            return response;
        });
    }

    /**
     * Organiazion edit - run when clicked on edit button next to organization's name
     *
     * */
    editOrg(item, oID) {
        localStorage.setItem(
            this.lastViewedOrganizationLocalStorageKey,
            JSON.stringify({
                oID,
                item,
            })
        );
        this.isLeftVisible = false;

        // set tab to edit section
        $(".organization-edit-nav")
            .find(".nav-link")
            .removeClass("active show");
        $(".organization-edit-nav").find(".nav-link").eq(0).trigger("click");

        if (item.key) {
            item = item.value;
        }

        if (item.children) {
            this.organization.childs = Object.values(item.children);
        } else {
            this.organization.childs = [];
        }

        this.organization.childNumber = item.childNo;
        this.organization.name = item.name;
        this.organization.address = item.address;
        this.organization.organizationID = item.organizationID;

        this.organization.address =
            this.organization.desc =
            this.organization.createdDate =
            null;

        if (item.organizationData) {
            this.selectedOrganizationData = item.organizationData;

            if (item.organizationData.address) {
                this.organization.address = item.organizationData.address;
            }

            if (item.organizationData.description) {
                this.organization.desc = item.organizationData.description;
            }

            if (item.organizationData.createdDate) {
                this.organization.createdDate =
                    item.organizationData.createdDate;
            }

            this.editOrganizationForm.get("name").setValue(item.name);
            this.editOrganizationForm
                .get("address")
                .setValue(item.organizationData.address);
            this.editOrganizationForm
                .get("desc")
                .setValue(item.organizationData.description);
        } else {
            this.editOrganizationForm.get("address").setValue("");
            this.editOrganizationForm.get("desc").setValue("");
            this.organization.rssArr = [];
        }
    }

    /**
     * Organitation deleted
     */
    deletedOrg(level, oID) {
        this.componentHelpers.startApiAction(
            () => {
                return this.service.deleteOrganization(oID);
            },
            {
                successMessageKey: "organization.success.deleteconfirm.message",
                failedMessageKey: "organization.fail.deleteconfirm.message",
                confirmMessageKey: "organization.deleteconfirm.message",
                confirmYesButtonTextKey: "organization.confirmDelete",
                afterSuccessAction: () => {
                    this.updateOrganization();
                },
            },
            true
        );
    }

    /**
     * Get social sites from API
     */
    getSocialSitesByOrgID(
        socialData: { socialSites: SocialSiteInterface[] } = {
            socialSites: [],
        },
        error,
        orgID
    ) {
        const socialSites = socialData.socialSites
            .filter((site) => {
                return site.organizationID === orgID;
            })
            .map((site) => {
                site.isExpireInFiveDays = false;

                if (site.pageAccessTokenExpire) {
                    site.isExpireInFiveDays = Utils.moment(
                        site.pageAccessTokenExpire
                    ).isBefore(Utils.moment().add(5, "day"));
                    site.accessTokenIsExpired = Utils.moment(
                        site.pageAccessTokenExpire
                    ).isBefore();
                }

                return site;
            });

        this.socialSitesPage = [];
        this.socialSitesUser = [];
        this.socialSitesGroup = [];
        this.linkedInPages = [];
        this.linkedInProfiles = [];
        this.twitterProfiles = [];
        this.instagramPages = [];
        this.gmbPages = [];

        socialSites.map((socialSite) => {
            if (socialSite.pageType === SITE_TYPE.Page) {
                if (socialSite.socialType === "linkedIn") {
                    this.linkedInPages.push(socialSite);
                }
                if (socialSite.socialType === SOCIAL_MEDIA_TYPE_FACEBOOK) {
                    this.socialSitesPage.push(socialSite);
                }
                if (socialSite.socialType === "instagram") {
                    this.instagramPages.push(socialSite);
                }
                if (socialSite.socialType === "gmb") {
                    this.gmbPages.push(socialSite);
                }
            }

            if (socialSite.pageType === "user") {
                if (socialSite.socialType === SOCIAL_MEDIA_TYPE_FACEBOOK) {
                    this.socialSitesUser.push(socialSite);
                }
                if (socialSite.socialType === SOCIAL_MEDIA_TYPE_LINKEDIN) {
                    this.linkedInProfiles.push(socialSite);
                }
                if (socialSite.socialType === SOCIAL_MEDIA_TYPE_TWITTER) {
                    this.twitterProfiles.push(socialSite);
                }
                if (socialSite.socialType === SOCIAL_MEDIA_TYPE_GMB) {
                    this.gmbProfiles.push(socialSite);
                }
            }

            if (socialSite.pageType === SITE_TYPE.Group) {
                this.socialSitesGroup.push(socialSite);
            }
        });
    }

    /**
     * Edit Social site modal
     *
     * @param id
     * @param name
     * @param timezone
     */
    openEditDialog(id, name, timezone) {
        this.dialog
            .open(SocialSiteEditDialogComponent, {
                data: {
                    id,
                    name,
                    timezone,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.socialSitesUser.length = 0;
                    this.socialSitesPage.length = 0;
                    this.socialSitesGroup.length = 0;
                    this.linkedInPages.length = 0;
                    this.socialNetworkGet();
                }
            });
    }

    /**
     * Refresh social sites access token user action method
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "group" | "profile"} type
     * @param {"facebook" | "linkedIn" | "instagram", "gmb"} socialType
     */
    refreshSocialSite(
        socialSite: SocialSiteInterface,
        type: TYPE_PAGE | TYPE_GROUP | TYPE_PROFILE,
        socialType: SOCIAL_PLATFORMS
    ) {
        if (!socialSite || !socialSite.siteID || !type || !socialType) {
            console.error("Missing parameters at 'refreshSocialSite' method.");
            return;
        }

        switch (socialType) {
            case SOCIAL_MEDIA_TYPE_FACEBOOK:
                this._refreshFacebookSite(
                    socialSite,
                    type as TYPE_PAGE | TYPE_GROUP
                );

                break;

            case SOCIAL_MEDIA_TYPE_LINKEDIN:
                this._refreshLinkedInSite(
                    socialSite,
                    type as TYPE_PAGE | TYPE_PROFILE
                );

                break;

            case SOCIAL_MEDIA_TYPE_TWITTER:
                this._refreshTwitterSite(socialSite, type as TYPE_PROFILE);

                break;

            case SOCIAL_MEDIA_TYPE_INSTAGRAM:
                this._refreshInstagramSite(socialSite, type as TYPE_PAGE);

                break;

            case SOCIAL_MEDIA_TYPE_GMB:
                this._refreshGmbSite(socialSite, type as TYPE_PAGE);

                break;

            default:
                console.warn(
                    `This social type (${socialType}) is not defined!`
                );

                break;
        }
    }

    /**
     * Delete social site
     *
     * @param {SocialSiteInterface} site
     * @param {SOCIAL_PLATFORMS} socialPlatform
     * @param {TYPE_PROFILE | TYPE_PAGE | TYPE_GROUP} type
     */
    deleteSocialSite(
        site: SocialSiteInterface,
        socialPlatform: SOCIAL_PLATFORMS,
        type: TYPE_PROFILE | TYPE_PAGE | TYPE_GROUP
    ) {
        const confirmMessageKeys = {
            [SOCIAL_PLATFORMS.Facebook]: {
                [SITE_TYPE.Page]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
                [SITE_TYPE.Group]: {
                    message: "organization.deletefbgroupconfirm.message",
                    yesBtn: "organization.confirmDeleteFbGroup",
                },
            },
            [SOCIAL_PLATFORMS.Instagram]: {
                [SITE_TYPE.Page]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
            },
            [SOCIAL_PLATFORMS.LinkedIn]: {
                [SITE_TYPE.Page]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
                [SITE_TYPE.Profile]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
            },
            [SOCIAL_PLATFORMS.Twitter]: {
                [SITE_TYPE.Profile]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
            },
            [SOCIAL_PLATFORMS.Gmb]: {
                [SITE_TYPE.Profile]: {
                    message: "organization.deletefbpageconfirm.message",
                    yesBtn: "organization.confirmDeleteFbPage",
                },
            },
        };
        const successMessageKeys = {
            [SOCIAL_PLATFORMS.Facebook]: {
                [SITE_TYPE.Page]:
                    "organization.success.deletefbpageconfirm.message",
                [SITE_TYPE.Group]:
                    "organization.success.deletefbgroupconfirm.message",
            },
            [SOCIAL_PLATFORMS.LinkedIn]: {
                [SITE_TYPE.Page]:
                    "organization.success.deletefbpageconfirm.message",
                [SITE_TYPE.Profile]:
                    "organization.success.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Twitter]: {
                [SITE_TYPE.Profile]:
                    "organization.success.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Instagram]: {
                [SITE_TYPE.Page]:
                    "organization.success.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Gmb]: {
                [SITE_TYPE.Profile]:
                    "organization.success.deletefbpageconfirm.message",
            },
        };
        const failedMessageKeys = {
            [SOCIAL_PLATFORMS.Facebook]: {
                [SITE_TYPE.Page]:
                    "organization.fail.deletefbpageconfirm.message",
                [SITE_TYPE.Group]:
                    "organization.fail.deletefbgroupconfirm.message",
            },
            [SOCIAL_PLATFORMS.LinkedIn]: {
                [SITE_TYPE.Page]:
                    "organization.fail.deletefbpageconfirm.message",
                [SITE_TYPE.Profile]:
                    "organization.fail.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Twitter]: {
                [SITE_TYPE.Profile]:
                    "organization.fail.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Instagram]: {
                [SITE_TYPE.Page]:
                    "organization.fail.deletefbpageconfirm.message",
            },
            [SOCIAL_PLATFORMS.Gmb]: {
                [SITE_TYPE.Profile]:
                    "organization.fail.deletefbpageconfirm.message",
            },
        };
        const successCallbacks = {
            [SOCIAL_PLATFORMS.Facebook]: () => {
                if (type === SITE_TYPE.Page) {
                    const index = this.socialSitesPage.findIndex((value) => {
                        return (
                            parseInt(value.siteID + "") ===
                            parseInt(site.siteID + "")
                        );
                    });

                    this.socialSitesPage.splice(index, 1);
                }

                if (type === SITE_TYPE.Group) {
                    const index = this.socialSitesGroup.findIndex((value) => {
                        return (
                            parseInt(value.siteID + "") ===
                            parseInt(site.siteID + "")
                        );
                    });

                    this.socialSitesGroup.splice(index, 1);
                }
            },
            [SOCIAL_PLATFORMS.LinkedIn]: () => {
                if (type === SITE_TYPE.Page) {
                    const index = this.linkedInPages.findIndex((value) => {
                        return (
                            parseInt(value.siteID + "") ===
                            parseInt(site.siteID + "")
                        );
                    });

                    this.linkedInPages.splice(index, 1);
                }

                if (type === SITE_TYPE.Profile) {
                    const index = this.linkedInProfiles.findIndex((value) => {
                        return (
                            parseInt(value.siteID + "") ===
                            parseInt(site.siteID + "")
                        );
                    });

                    this.linkedInProfiles.splice(index, 1);
                }
            },
            [SOCIAL_PLATFORMS.Twitter]: () => {
                const index = this.twitterProfiles.findIndex((value) => {
                    return (
                        parseInt(value.siteID + "") ===
                        parseInt(site.siteID + "")
                    );
                });

                this.twitterProfiles.splice(index, 1);
            },
            [SOCIAL_PLATFORMS.Instagram]: () => {
                if (type === SITE_TYPE.Page) {
                    const index = this.instagramPages.findIndex((value) => {
                        return (
                            parseInt(value.siteID + "") ===
                            parseInt(site.siteID + "")
                        );
                    });

                    this.instagramPages.splice(index, 1);
                }
            },
            [SOCIAL_PLATFORMS.Gmb]: () => {
                const index = this.gmbPages.findIndex((value) => {
                    return (
                        parseInt(value.siteID + "") ===
                        parseInt(site.siteID + "")
                    );
                });

                this.gmbPages.splice(index, 1);
            },
        };

        this.componentHelpers.startApiAction(
            () => {
                return this.socialManagerComponent.deletePage(site.siteID);
            },
            {
                confirmMessageKey:
                    confirmMessageKeys[socialPlatform][type].message,
                confirmYesButtonTextKey:
                    confirmMessageKeys[socialPlatform][type].yesBtn,
                successMessageKey: successMessageKeys[socialPlatform][type],
                failedMessageKey: failedMessageKeys[socialPlatform][type],
                afterSuccessAction: successCallbacks[socialPlatform],
            },
            true
        );
    }

    addTwitterSite() {
        this.twitterAPI.authenticate((data) => {
            if (data.successAuth) {
                this.componentHelpers.startApiAction(
                    () => {
                        return this.socialManagerComponent.addProfiles([
                            {
                                socialType: SOCIAL_MEDIA_TYPE_TWITTER,
                                organizationID:
                                    this.organization.organizationID.toString(),
                                userID: data.twitterToken.user_id,
                                userName: data.twitterToken.screen_name,
                                accessToken: data.twitterToken.oauth_token,
                                accessTokenSecret:
                                    data.twitterToken.oauth_token_secret,
                            },
                        ]);
                    },
                    {
                        failedMessageKey:
                            "organization.connect.twitter.site.failed",
                        showSuccessModal: false,
                        afterSuccessAction: () => {
                            this.socialNetworkGet();
                        },
                    }
                );
            } else {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        data.message ||
                        Utils.get(data, "error.message", null) ||
                        LanguageService.getLine(
                            "organization.connect.twitter.site.failed"
                        ),
                });
            }
        });
    }

    /**
     * Add new google account
     */
    addGmbSite() {
        this.gmbApiService.authorization().then(() => {
            this.dialog
                .open(GmbLocationsDialogComponent, {
                    data: {
                        dialogTitle: LanguageService.getLine(
                            "social.dialog.title.location"
                        ),
                        headLine: LanguageService.getLine(
                            "social.select.locations"
                        ),
                        organizationID: this.organization.organizationID,
                    },
                })
                .afterClosed()
                .subscribe(
                    (result) => result ? this.socialNetworkGet() : null,
                    (error) => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message:
                                error.message ||
                                Utils.get(error, 'error.message', null) ||
                                LanguageService.getLine(
                                    'organization.connect.google.site.failed'
                                ),
                        });
                    }
                );
        })
            .catch((e) => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        e.message ||
                        Utils.get(e, 'error.message', null) ||
                        LanguageService.getLine(
                            'organization.connect.google.site.failed'
                        ),
                });
            });
    }

    /**
     * Check facebook login and get social pages
     *
     */
    facebookLoginPage() {
        const orgID = this.organization.organizationID;
        this.facebookLogin("page")
            .then((res) => {
                if (!!res) {
                    this.dialog
                        .open(FacebookDialogComponent, {
                            data: {
                                organizationID: orgID,
                                page: "page",
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (result) {
                                this.socialNetworkGet();
                            }
                        });
                }
            })
            .catch((e) => {
                return;
            });
    }

    /**
     * Check facebook login and get insta pages
     *
     */
    instagramLoginPage() {
        const orgID = this.organization.organizationID;
        this.facebookLogin("page")
            .then((res) => {
                if (!!res) {
                    this.dialog
                        .open(FacebookDialogComponent, {
                            data: {
                                organizationID: orgID,
                                page: "instagram",
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (result) {
                                this.socialNetworkGet();
                            }
                        });
                }
            })
            .catch((e) => {
                return;
            });
    }

    /**
     * Check facebook login and get social groups
     *
     */
    facebookLoginGroup() {
        const orgID = this.organization.organizationID;
        this.facebookLogin("group").then((res) => {
            if (!!res) {
                this.dialog
                    .open(FacebookDialogComponent, {
                        data: {
                            organizationID: orgID,
                            page: "group",
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result) {
                            this.socialNetworkGet();
                        }
                    });
            }
        });
    }

    facebookLogin(type: "page" | "group" | "simple" = "simple") {
        return this.fb.loginWithOptions(type).then((res) => {
            if (res.status === "connected") {
                return res;
            }

            return false;
        });
    }

    /**
     * Change organization's name
     */
    changeName() {
        if (!this.editOrganizationForm.valid) {
            this.editOrganizationFormErrors = FormValidationService.getMessages(
                this.editOrganizationForm.controls
            );
        }
    }

    /**
     * Change organization's address
     */
    changeAddress() {
        if (!this.editOrganizationForm.valid) {
            this.editOrganizationFormErrors = FormValidationService.getMessages(
                this.editOrganizationForm.controls
            );
        }
    }

    /**
     * Change organization's description
     */
    changeDesc() {
        if (!this.editOrganizationForm.valid) {
            this.editOrganizationFormErrors = FormValidationService.getMessages(
                this.editOrganizationForm.controls
            );
        }
    }

    /**
     * Submit handler for organization edit form
     *
     * @param event event object
     */
    submitOrganizationEdit(event) {
        event.preventDefault();

        const name = this.organizationName;
        const address = this.organizationAddress;
        const desc = this.organizationDesc;
        const organizationID = this.organization.organizationID;

        if (this.editOrganizationForm.valid) {
            // send organization datas
            const sendFormDataObj = new FormData();
            sendFormDataObj.append("name", name);
            sendFormDataObj.append("address", address);
            sendFormDataObj.append("description", desc);

            const _loader = this.openModal.loader(DialogLoaderComponent);

            this.http
                .post(
                    environment.apiUrl + `/api/organization/${organizationID}`,
                    sendFormDataObj,
                    Helpers.getBaseHttpHeaders(Token.getToken())
                )
                .toPromise()
                .then((data) => {
                    this.isEditSuccess = true;
                    this.organization.name = name;
                    this.organization.address = address;
                    this.organization.desc = desc;

                    _loader.afterClosed().subscribe(() => {
                        this.openModal.successModal(DialogSuccessComponent, {
                            message: LanguageService.getLine(
                                "organization.edit.success"
                            ),
                        });
                    });

                    this.service
                        .getOrganization()
                        .then((data) => {
                            this.organizationTree = data["organizationTree"];
                            this.showedOrganizations = [
                                {
                                    children: {
                                        ...this.organizationTree,
                                    },
                                },
                            ];
                        })
                        .finally(() => _loader.close());
                })
                .catch((e) => {
                    this.isEditSuccess = false;

                    _loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: LanguageService.getLine(
                                "organization.edit.fail"
                            ),
                        });
                    });

                    _loader.close();
                });
        } else {
            this.editOrganizationFormErrors = FormValidationService.getMessages(
                this.editOrganizationForm.controls
            );
        }
    }

    /**
     * Getters for organization edit form
     * */
    get organizationName(): any {
        return this.editOrganizationForm.get("name").value;
    }

    get organizationAddress(): any {
        return this.editOrganizationForm.get("address").value;
    }

    get organizationDesc(): any {
        return this.editOrganizationForm.get("desc").value;
    }

    get organizationFeedUrl(): any {
        return this.editOrganizationForm.get("feedUrl").value;
    }

    get partnerPermissions() {
        return PartnerPermissions;
    }

    /**
     * Users tab - filter by ID
     * @param value userID string
     */
    userIDFilter(value): void {
        const filter = this.getTableFilter(
            "uid",
            value ? parseInt(value, 10) : "",
            "number"
        );

        this.orgUserSource.filter = filter;

        if (this.orgUserSource.paginator) {
            this.orgUserSource.paginator.firstPage();
        }
    }

    /**
     *  Users tab - filter by Name
     * @param value username string
     */
    userNameFilter(value): void {
        const filter = this.getTableFilter("name", value, "string");

        this.orgUserSource.filter = filter;

        if (this.orgUserSource.paginator) {
            this.orgUserSource.paginator.firstPage();
        }
    }

    resetUserFilter() {
        this.userIdFilterInput.nativeElement.value = "";
        this.userNameFilterInput.nativeElement.value = "";

        this.orgUserSource.filter = "";

        if (this.orgUserSource.paginator) {
            this.orgUserSource.paginator.firstPage();
        }
    }

    getTableFilter(property, value, type) {
        const removeFilter = (prop) => {
            this.userTableFilter.map((item, index) => {
                if (item["id"] === prop) {
                    this.userTableFilter.splice(index, 1);
                }
            });
        };

        if (value instanceof String) {
            value = value.trim().toLowerCase();
        }

        removeFilter(property);

        if (!this.userIdFilterInput.nativeElement.value) {
            removeFilter("uid");
        }

        if (!this.userNameFilterInput.nativeElement.value) {
            removeFilter("name");
        }

        if (value !== "") {
            this.userTableFilter.push({
                id: property,
                value: value,
                type: type,
            });
        }

        if (this.userTableFilter.length) {
            return JSON.stringify(this.userTableFilter);
        }

        return "";
    }

    /**
     * Search social pages
     */
    searchInSocial(event) {
        let currSocialID;
        // check active social by tabs
        if (this.activeSocialNetwork === SOCIAL_MEDIA_TYPE_FACEBOOK) {
            currSocialID = "facebookSocialNetwork";
        }

        let txt = event.target.value;
        txt = txt.toLowerCase();
        const $socialWrapper = $("#" + currSocialID).find(
            ".social-network-items"
        );
        const $socialBoxes = $socialWrapper
            .find(".social-network-item")
            .not(".is-added");

        if (txt.length > 0) {
            $.each($socialBoxes, function () {
                const $this = $(this);
                let title = $this.find(".item-name").text();
                title = title.toLowerCase();

                if (title.includes(txt)) {
                    $this.show();
                } else {
                    $this.hide();
                }
            });
        } else {
            $socialBoxes.show();
        }
    }

    /**
     * Open Dialog
     */
    createOrgModal() {
        const createModal = this.dialog.open(CreateOrgModalComponent, {
            width: "700px",
            data: {
                title: "Create New Organization",
            },
        });

        createModal.componentInstance.submitEvent.subscribe(() => {
            this.updateOrganization();
        });
    }

    /**
     *
     * Get all social pages for organization
     *
     * @param event
     */
    @Debounce()
    socialNetworkGet(): Promise<any> {
        const orgID = this.organization.organizationID;


        const _loader = this.openModal.loader(DialogLoaderComponent);

        this.socialSitesPage = [];
        this.socialSitesUser = [];
        this.socialSitesGroup = [];
        this.linkedInPages = [];
        this.twitterProfiles = [];
        this.instagramPages = [];
        this.gmbPages = [];

        return new Promise<any>((resolve, reject) => {
            this.socialSiteService
                .getAll({
                    organizationID: [orgID],
                    socialType: this.isActiveSocialChannelId,
                })
                .then((data) => {
                    this.getSocialSitesByOrgID(data, undefined, orgID);

                    _loader.afterClosed().subscribe(() => {
                        resolve(data);
                    });

                    _loader.close();
                })
                .catch((err) => {
                    this.getSocialSitesByOrgID(undefined, err, orgID);

                    _loader.afterClosed().subscribe(() => {
                        reject(err);
                    });

                    _loader.close();
                });
        });
    }

    /**
     * Get all users for organization
     */
    userGet() {
        const _loader = this.openModal.loader(DialogLoaderComponent);

        this.orgUserSource.data = [];

        this.usersComponent.filters["organizationID"] =
            this.organization.organizationID;
        this.usersComponent.filters["singleOrg"] = true;
        delete this.usersComponent.filters["limit"];

        this.usersComponent.getItems(
            (response) => {
                this.users = response.users;

                _loader.close();

                this.orgUsers = [];

                if (this.users && this.users.length > 0) {
                    this.orgUsers = this.users;

                    const usersFiltered = [];

                    this.orgUsers.forEach((item) => {
                        const roleName = item.organizations.find(
                            (organization) =>
                                organization.organizationID ==
                                this.organization.organizationID
                        ).roleName;
                        const tmpObj = {
                            uid: item.userID,
                            name: item.fullName,
                            email: item.email,
                            regdate: item.createDate,
                            statusMessage: item.statusMessage,
                            roleName: roleName,
                        };

                        usersFiltered.push(tmpObj);
                    });

                    if (usersFiltered.length > 0) {
                        this.orgUserSource.data = usersFiltered;
                    } else {
                        this.orgUserSource.data = [];
                    }
                }
            },
            (error) => { }
        );
    }

    paginationChange(event: any) {
        this.usersComponent.filters["limit"] = event.pageSize;
        this.usersComponent.filters["offset"] = event.pageIndex;

        this.userGet();
    }

    /**
     * Step back for organization tree
     */
    triggerBackBtn(event) {
        this.isLeftVisible = true;
        window.scrollTo(0, 0);
        /*
         * Check edit process was successfully
         */
        if (this.isEditSuccess) {
            this.getOrganizations();

            this.isEditSuccess = false;
        }
    }

    /**
     * @description most éppen működik egy csúnya assets-ből node module használatával, de... https://github.com/NarHakobyan/ngx-linkedIn
     * ezzel talán szebb és működik is.
     */
    addLinkedIn() {
        this.loginWithLinkedIn(true, () => {
            this.openLinkedInPageSelectionPopup();
        });
    }

    public loginWithLinkedIn(
        isForced = false,
        successCallback: () => void = null
    ) {
        const success = (event: CustomEvent) => {
            // window.removeEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
            // window.removeEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);

            if (isForced) {
                setTimeout(() => {
                    this.linkedInLoginWindow.close();
                }, 5000);
            }

            const loader = this.openModal.loader(DialogLoaderComponent);
            this.linkedinService
                .handleLinkedInTokenByCode(event.detail.code)
                .then(() => {
                    this.isLoggedInWithLinkedIn =
                        this.linkedinService.isLoggedIn();

                    loader.afterClosed().subscribe(() => {
                        if (!!successCallback) {
                            successCallback();
                        } else {
                            this.socialNetworkGet();
                        }
                    });
                    loader.close();
                })
                .catch((err) => {
                    if (err && (err.massage || err.error_description)) {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message: err.message || err.error_description,
                            });
                        });
                    }

                    loader.close();
                });
        };
        const failed = (event: CustomEvent) => {
            // window.removeEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
            // window.removeEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);

            this.openModal.errorModal(DialogErrorComponent, {
                message: event.detail.message,
            });
        };

        window["smdData"] = {
            forceLogin: isForced,
            success: (event: CustomEvent) => {
                success(event);
            },
            failed: (event: CustomEvent) => {
                failed(event);
            },
        };

        const windowFeatures =
            "location=yes,height=570,width=520,scrollbars=no,status=no";
        this.linkedInLoginWindow = window.open(
            this.linkedinService.url,
            null,
            windowFeatures
        );

        // window.addEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
        // window.addEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);
    }

    addLinkedInProfile() {
        this.loginWithLinkedIn(true, () => {
            this.openLinkedInProfileModal();
        });
    }

    private openLinkedInProfileModal() {
        this.dialog
            .open(FacebookDialogComponent, {
                data: {
                    organizationID: this.organization.organizationID,
                    page: "linkedInProfile",
                    addAnotherPageMethod: () => {
                        this.addLinkedInProfile();
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.socialNetworkGet();
                }
            });
    }

    private openLinkedInPageSelectionPopup() {
        this.dialog
            .open(FacebookDialogComponent, {
                data: {
                    organizationID: this.organization.organizationID,
                    page: "linkedInPage",
                    addAnotherPageMethod: () => {
                        this.addLinkedIn();
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.socialNetworkGet();
                }
            });
    }

    get languageService() {
        return LanguageService;
    }
    /**
     * Refresh instagram site access token
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "group"} type
     * @private
     */
    private _refreshInstagramSite(
        socialSite: SocialSiteInterface,
        type: TYPE_PAGE | TYPE_GROUP
    ) {
        this.facebookLogin(type).then((res) => {
            if (!!res) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                const methods = {
                    page: "getPages",
                };

                this.fb[methods[type]]()
                    .then(async (response) => {
                        try {
                            let fbPages = response.data;
                            const businessReturn =
                                await this.getInstaBusinesses(fbPages);
                            const businessPages = await businessReturn.filter(
                                (businessPage) => businessPage !== null
                            );

                            let page = await businessPages.find(
                                (item) => item.id == socialSite.pageID
                            );
                            if (!!page) {
                                this.fb
                                    .getPageAccessToken(
                                        page.facebook_page_id,
                                        this.backend
                                    )
                                    .then((access) => {
                                        this.socialManagerComponent
                                            .editPage(socialSite.siteID, {
                                                name: socialSite.name,
                                                timezone: socialSite.timezone,
                                                pageAccessToken:
                                                    type === "group"
                                                        ? FacebookUser.getData().access_token
                                                        : access.accessToken,
                                                userAccessToken:
                                                    type === "group"
                                                        ? FacebookUser.getData().access_token
                                                        : access.userAccessToken,
                                                pageAccessTokenExpire:
                                                    SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                                                connectedFacebookPageID: page.facebook_page_id,
                                            })
                                            .then((editResponse) => {
                                                loader
                                                    .afterClosed()
                                                    .subscribe(() => {
                                                        this.openModal
                                                            .successModal(
                                                                DialogSuccessComponent,
                                                                {
                                                                    message:
                                                                        LanguageService.getLine(
                                                                            "social.sites.refresh.success"
                                                                        ),
                                                                }
                                                            )
                                                            .afterClosed()
                                                            .subscribe(() => {
                                                                this.socialNetworkGet();
                                                            });
                                                    });

                                                loader.close();
                                            })
                                            .catch((editError) => {
                                                loader
                                                    .afterClosed()
                                                    .subscribe(() => {
                                                        this.openModal.errorModal(
                                                            DialogErrorComponent,
                                                            {
                                                                message:
                                                                    FormValidationService.readError(
                                                                        editError
                                                                    ).message,
                                                            }
                                                        );
                                                    });

                                                loader.close();
                                            });
                                    });
                            } else {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal.errorModal(
                                        DialogErrorComponent,
                                        {
                                            message: LanguageService.getLine(
                                                "organization.socialSite.error.refresh"
                                            ),
                                        }
                                    );
                                });

                                loader.close();
                            }
                        } catch (e) {
                            loader.afterClosed().subscribe(() => {
                                this.openModal.errorModal(
                                    DialogErrorComponent,
                                    {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    }
                                );
                            });
                            loader.close();
                        }
                    })
                    .catch((error) => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message: LanguageService.getLine(
                                    "organization.socialSite.error.refresh"
                                ),
                            });
                        });
                        loader.close();
                    });
            }
        });
    }

    private _refreshGmbSite(socialSite: SocialSiteInterface, type: TYPE_PAGE) {
        this.gmbApiService.authorization().then(() => {
            const loader = this.openModal.loader(DialogLoaderComponent);
            this.gmbApiService.get().then((response: any) => {
                if (
                    response.length > 0 && response.map(item => (item.location)).filter(Boolean).length > 0
                ) {
                    const splitID = socialSite.pageID.split("/");
                    const pageID = splitID.length === 4 ? splitID[3] : null;
                    const location = response.find(
                        (item) => item.location.name.split("/")[1] === pageID
                    );

                    if (location) {
                        this.gmbApiService
                            .refresh(socialSite.siteID)
                            .then(() => {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal
                                        .successModal(DialogSuccessComponent, {
                                            message: LanguageService.getLine(
                                                "social.sites.refresh.success"
                                            ),
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.socialNetworkGet();
                                        });
                                });
                                loader.close();
                            })
                            .catch((response) => {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal.errorModal(
                                        DialogErrorComponent,
                                        {
                                            message: LanguageService.getLine(
                                                "organization.socialSite.error.refresh"
                                            ),
                                        }
                                    );
                                });
                                loader.close();
                            });
                    } else {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: LanguageService.getLine(
                                "organization.socialSite.error.refresh"
                            ),
                        });
                        loader.close();
                    }
                }
            });
        });
    }

    /**
     * Refresh facebook site access token
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "group"} type
     * @private
     */
    private _refreshFacebookSite(
        socialSite: SocialSiteInterface,
        type: TYPE_PAGE | TYPE_GROUP
    ) {
        this.facebookLogin(type).then((res) => {
            if (!!res) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                const methods = {
                    page: "getPages",
                    group: "getGroups",
                };

                this.fb[methods[type]]()
                    .then((response) => {
                        const page = response.data.find(
                            (item) => item.id === socialSite.pageID
                        );

                        if (!!page) {
                            this.socialManagerComponent
                                .editPage(socialSite.siteID, {
                                    name: socialSite.name,
                                    timezone: socialSite.timezone,
                                    pageAccessToken:
                                        type === "group"
                                            ? FacebookUser.getData()
                                                .access_token
                                            : page.access_token,
                                    pageAccessTokenExpire:
                                        SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                                })
                                .then((editResponse) => {
                                    loader.afterClosed().subscribe(() => {
                                        this.openModal
                                            .successModal(
                                                DialogSuccessComponent,
                                                {
                                                    message:
                                                        LanguageService.getLine(
                                                            "social.sites.refresh.success"
                                                        ),
                                                }
                                            )
                                            .afterClosed()
                                            .subscribe(() => {
                                                this.socialNetworkGet();
                                            });
                                    });

                                    loader.close();
                                })
                                .catch((editError) => {
                                    loader.afterClosed().subscribe(() => {
                                        this.openModal.errorModal(
                                            DialogErrorComponent,
                                            {
                                                message:
                                                    FormValidationService.readError(
                                                        editError
                                                    ).message,
                                            }
                                        );
                                    });

                                    loader.close();
                                });
                        } else {
                            loader.afterClosed().subscribe(() => {
                                this.openModal.errorModal(
                                    DialogErrorComponent,
                                    {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    }
                                );
                            });

                            loader.close();
                        }
                    })
                    .catch((error) => {
                        loader.close();
                    });
            }
        });
    }

    /**
     * Refresh LinkedIn sites access token
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "profile"} type
     * @private
     */
    private _refreshLinkedInSite(
        socialSite: SocialSiteInterface,
        type: TYPE_PAGE | TYPE_PROFILE
    ) {
        const editPage = (loader, condition: any) => {
            if (!!condition) {
                const data = {
                    name: socialSite.name,
                    timezone: socialSite.timezone,
                    pageAccessToken: this.linkedinService.token,
                };

                if (this.linkedinService.expiresIn) {
                    data["pageAccessTokenExpire"] =
                        this.linkedinService.expiresIn;
                }

                this.socialManagerComponent
                    .editPage(socialSite.siteID, data)
                    .then(() => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal
                                .successModal(DialogSuccessComponent, {
                                    message: LanguageService.getLine(
                                        "social.sites.refresh.success"
                                    ),
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.socialNetworkGet();
                                });
                        });

                        loader.close();
                    })
                    .catch((editError) => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message:
                                    FormValidationService.readError(editError)
                                        .message,
                            });
                        });

                        loader.close();
                    });
            } else {
                loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: LanguageService.getLine(
                            "organization.socialSite.error.refresh"
                        ),
                    });
                });

                loader.close();
            }
        };

        this.loginWithLinkedIn(false, () => {
            const loader = this.openModal.loader(DialogLoaderComponent);

            switch (type) {
                case SITE_TYPE.Page:
                    this.linkedinService
                        .getPages()
                        .then((response) => {
                            const condition = response.elements.find(
                                (item: any) =>
                                    item.organizationalTarget ===
                                    socialSite.pageID
                            );

                            editPage(loader, condition);
                        })
                        .catch(() => loader.close());

                    break;

                case SITE_TYPE.Profile:
                    this.linkedinService
                        .getMe()
                        .then((response: any) => {
                            const condition =
                                `urn:li:person:${response.id}` ===
                                socialSite.pageID;

                            editPage(loader, condition);
                        })
                        .catch(() => loader.close());

                    break;

                default:
                    console.warn(`This site type (${type}) is not defined.`);
                    loader.close();

                    break;
            }
        });
    }

    /**
     * @param {SocialSiteInterface} socialSite
     * @param {TYPE_PROFILE} type
     * @private
     */
    private _refreshTwitterSite(
        socialSite: SocialSiteInterface,
        type: TYPE_PROFILE
    ) {
        this.twitterAPI.authenticate((data) => {
            if (data.successAuth) {
                this.componentHelpers.startApiAction(
                    () => {
                        if (
                            socialSite.pageID.toString() ===
                            data.twitterToken.user_id.toString()
                        ) {
                            return this.socialManagerComponent.editPage(
                                socialSite.siteID,
                                {
                                    name: socialSite.name,
                                    timezone: socialSite.timezone,
                                    pageAccessToken:
                                        data.twitterToken.oauth_token,
                                    accessTokenSecret:
                                        data.twitterToken.oauth_token_secret,
                                }
                            );
                        } else {
                            return Promise.reject({
                                error: {
                                    error: {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    },
                                },
                            });
                        }
                    },
                    {
                        successMessageKey: "social.sites.refresh.success",
                        failedMessageKey:
                            "organization.refresh.twitter.site.failed",
                        afterSuccessAction: () => {
                            this.socialNetworkGet();
                        },
                    }
                );
            } else {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        data.message ||
                        Utils.get(data, "error.message", null) ||
                        LanguageService.getLine(
                            "organization.refresh.twitter.site.failed"
                        ),
                });
            }
        });
    }

    async getInstaBusinesses(fbPages) {
        const promises = [];
        for (const page of fbPages) {
            promises.push(this.getInstaPages(page));
        }

        const responses = await Promise.all(promises);
        const instaPages = responses.filter((response) => response !== null);
        const businessPromises = [];
        for (const response of instaPages) {
            if (response) {
                businessPromises.push(this.getBusinessInstaPages(response));
            }
        }
        return await Promise.all(businessPromises);
    }

    async getInstaPages(page) {
        try {
            const response = await this.fb.getInstagramPages(
                page.id,
                page.access_token
            );
            if (response.data.length) {
                //console.log("Found instagram page connected to: " + page.name);
                return {
                    facebook_page_id: page.id,
                    access_token: page.access_token,
                    name: response.data[0]["username"],
                };
            } else {
                return null;
            }
        } catch (e) {
            //console.log("getInstaPage error:");
            //console.log(e);
            return null;
        }
    }

    async getBusinessInstaPages(page) {
        try {
            const response = await this.fb.getInstagramBusinessID(
                page.facebook_page_id,
                page.access_token
            );
            if (response.instagram_business_account) {
                return { ...page, id: response.instagram_business_account.id };
            } else {
                return null;
            }
        } catch (e) {
            //console.log("getBusinessInstaPages error:");
            //console.log(e);
            return null;
        }
    }

    sortByName(a: KeyValue<string, Array<string>>, b: KeyValue<string, Array<string>>) {
        if (a.value["name"] === b.value["name"]) {
            return 0
        } else if (a.value["name"] === null) {
            return -1;
        } else if (b.value["name"] === null) {
            return 1;
        }
        return a.value["name"].toString().localeCompare(b.value["name"].toString());
    }

    toggleComponent() {

        this.showComponentA = !this.showComponentA;

        if (this.showComponentA) {
            this.title = "Connected Social Sites - Grouped by Platforms";

        } else {
            this.title = "Connected Social Sites - Grouped by Organization";
        }
    }

    onSocialPlatformsChange(socialPlatforms, isWithoutReload = false) {
        const activePlatforms = socialPlatforms
            .filter((platform) => platform.isActive)
            .map((platform) => platform.id);
        this.OrganizationGroupbyService.changeFilterData(activePlatforms);
    }
    selectionChange(socialPlatforms) {
        const activePlatforms = socialPlatforms;
        this.OrganizationGroupbyService.changeFilterData(activePlatforms);
    }

}
